<template>
  <v-container class="text-center pt-15">
    <v-row justify="center" class="mt-5 mb-5">
      <v-col cols="12">
        <v-card>
          <v-card-title class="d-flex align-center justify-center pa-3">
            <v-row justify="center" no-gutters align="center">
              <v-col cols="12" md="2" class="text-center" v-show="$vuetify.breakpoint.smAndUp">
                <v-card-title>Filtrar por:</v-card-title>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="selectedPeriod"
                  :items="periods"
                  label="Tipo de periodo"
                  prepend-icon="mdi-calendar"
                  dense
                  class="mx-2 mt-5"
                  :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '190px' : '' }"
                  @change="searchByPeriod"
                ></v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="selectedYear"
                  :items="years"
                  label="Año"
                  prepend-icon="mdi-calendar"
                  dense
                  class="mx-2 mt-5"
                  :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '190px' : '' }"
                  @change="searchByYear"
                ></v-select>
              </v-col>

              <v-col cols="12" md="2">
                <v-select
                  v-model="selectedMonth"
                  :items="months"
                  label="Mes"
                  prepend-icon="mdi-calendar"
                  dense
                  class="mx-2 mt-5"
                  :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '190px' : '' }"
                  @change="searchByMonth"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" class="d-flex justify-end">
                <v-btn
                  @click="generateExcel()"
                  small
                  rounded
                  color="success"
                  class="text-normal white--text mx-1 d-none d-md-flex"
                  v-if="tempItems.length && rol == 'auditor'"
                  :disabled="tempItems.length < 1 || loading"
                  :loading="loading"
                >
                  <v-icon left>mdi-download</v-icon>
                  Descargar excel
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-end"
        ><h4>
          Tareas completadas: {{ finishedTasks }} / {{ tempItems.length }}
          <span v-if="tempItems.length > 0"> ({{ ((finishedTasks / tempItems.length) * 100).toFixed(2) }}%) </span>
        </h4></v-col
      >
    </v-row>
    <!-- <v-text-field v-model="search" append-icon="mdi-magnify" label="Search..." outlined dense class="mb-4"></v-text-field> -->
    <v-data-table :headers="headers" :items="tempItems" class="elevation-1" item-value="id" :items-per-page="10">
      <template v-slot:[`item.tipo`]="{ item }">
        {{ item.tipo ? 'Mensual' : 'Anual' }}
      </template>

      <template v-slot:[`item.estatus`]="{ item }">
        <div v-if="rol !== 'auditor'">
          <v-menu v-model="item.menu" close-on-content-click nudge-width="200" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                :color="getStatusColor(getStatusText(item.estatus))"
                small
                class="status-chip"
                text-center
                :style="{ color: getStatusText(item.estatus) === 'En progreso' ? 'black' : 'white' }"
              >
                {{ getStatusText(item.estatus) }}
              </v-chip>
            </template>

            <v-list>
              <v-list-item v-for="(status, index) in statusOptions" :key="index" @click="updateStatus(item, status)">
                <v-list-item-title>{{ status }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          <!-- <v-chip :color="getStatusColor(getStatusText(item.estatus))" outlined small class="status-chip" text-center> -->
          <v-chip
            :color="getStatusColor(getStatusText(item.estatus))"
            small
            class="status-chip"
            text-center
            :style="{ color: getStatusText(item.estatus) === 'En progreso' ? 'black' : 'white' }"
          >
            {{ getStatusText(item.estatus) }}
          </v-chip>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import services from '@/utils/services';
import * as XLSX from 'xlsx';

export default {
  data() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    return {
      headers: [
        // { text: 'Empresa', value: 'nombre_empresa' },
        // { text: 'Ubicación', value: 'locacion' },
        // { text: 'Nombre', value: 'userName' },
        // { text: 'Fuente de emisión', value: 'nombre_fuente_emision' },
        // { text: 'Tipo de periodo', value: 'tipo' },
        // // { text: 'Período de reporte', value: 'reportPeriod' },
        // { text: 'Fecha', value: 'fecha' },
        // { text: 'Estatus', value: 'estatus', width: '10%' }
      ],
      statusOptions: ['Pendiente', 'En progreso', 'Completado'],
      items: [],
      tempItems: [],
      rol: '',
      userName: '',
      selectedPeriod: 'Mensual',
      selectedYear: currentYear,
      selectedMonth: currentMonth,
      periods: ['Anual', 'Mensual'],
      userCompanies: [],
      finishedTasks: 0,
      loading: false,
      years: Array.from({ length: currentYear - 2020 + 1 }, (_, i) => currentYear - i),
      months: [
        { text: 'Enero', value: 0 },
        { text: 'Febrero', value: 1 },
        { text: 'Marzo', value: 2 },
        { text: 'Abril', value: 3 },
        { text: 'Mayo', value: 4 },
        { text: 'Junio', value: 5 },
        { text: 'Julio', value: 6 },
        { text: 'Agosto', value: 7 },
        { text: 'Septiembre', value: 8 },
        { text: 'Octubre', value: 9 },
        { text: 'Noviembre', value: 10 },
        { text: 'Diciembre', value: 11 }
      ]
    };
  },
  methods: {
    initializeHeaders() {
      if (this.rol === 'auditor') {
        this.headers = [
          { text: 'Empresa', value: 'nombre_empresa' },
          { text: 'Ubicación', value: 'locacion' },
          { text: 'Usuario', value: 'email_usuario' },
          { text: 'Fuente de emisión', value: 'nombre_fuente_emision' },
          { text: 'Tipo de periodo', value: 'tipo' },
          { text: 'Fecha', value: 'fecha' },
          { text: 'Estatus', value: 'estatus', width: '10%' }
        ];
      } else {
        this.headers = [
          { text: 'Empresa', value: 'nombre_empresa' },
          { text: 'Ubicación', value: 'locacion' },
          { text: 'Nombre', value: 'userName' },
          { text: 'Fuente de emisión', value: 'nombre_fuente_emision' },
          { text: 'Tipo de periodo', value: 'tipo' },
          { text: 'Fecha', value: 'fecha' },
          { text: 'Estatus', value: 'estatus', width: '10%' }
        ];
      }
    },

    async fetchTasks() {
      try {
        const idUsuario = this.$session.get('id');
        // const endpoint = this.selectedPeriod === 'Mensual' ? services.routes.getMonthlyTasks : services.routes.getAnnualTasks;

        const endpoint =
          this.rol === 'auditor'
            ? this.selectedPeriod === 'Mensual'
              ? services.routes.getAllMonthlyTasks
              : services.routes.getAllAnnualTasks
            : this.selectedPeriod === 'Mensual'
            ? services.routes.getMonthlyTasks
            : services.routes.getAnnualTasks;

        // const response = await this.axios.get(endpoint, {
        //   params: { idUsuario }
        // });

        const config = this.rol === 'auditor' ? {} : { params: { idUsuario } };

        const response = await this.axios.get(endpoint, config);
        console.log('Esta es la respuesta ', response);
        this.items = response.data.map((element) => {
          const fechaObj = new Date(element.fecha);
          return {
            ...element,
            userName: this.userName,
            fecha: fechaObj.toLocaleDateString('es-ES', {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
            }),
            year: fechaObj.getFullYear(),
            month: fechaObj.getMonth()
          };
        });
        if (this.rol === 'auditor') {
          // Filtrar los items para incluir solo aquellos con `nombre_empresa` en `this.userCompanies`
          this.items = this.items.filter((item) => this.userCompanies.includes(item.nombre_empresa));
        }

        // Aplica el filtro inicial
        this.applyFilters();
      } catch (error) {
        console.error('Error al obtener las tareas:', error);
      }
    },

    updateStatus(item, status) {
      console.log('Se cambio el estatus', item);

      const statusMapping = {
        Pendiente: 0,
        'En progreso': 1,
        Completado: 2
      };
      item.estatus = statusMapping[status] !== undefined ? statusMapping[status] : null;

      let idTarea;
      item.tipo ? (idTarea = item.idTareaResponsabilidades) : (idTarea = item.idTareaResponsabilidadesAnuales);

      let taskUpdate = {
        idTareaResponsabilidades: idTarea,
        estatus: item.estatus,
        tipo: item.tipo
      };
      console.log(taskUpdate);

      this.axios
        .patch(services.routes.updateTasks, taskUpdate)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.getFinishedTasks();
        });
    },

    getStatusText(estatus) {
      switch (estatus) {
        case 0:
          return 'Pendiente';
        case 1:
          return 'En progreso';
        case 2:
          return 'Completado';
        default:
          return 'Desconocido';
      }
    },
    getStatusColor(statusText) {
      switch (statusText) {
        case 'En progreso':
          return '#ffde59';
        case 'Completado':
          return '#4caf50';
        case 'Pendiente':
          return 'red';
        default:
          return 'grey';
      }
    },
    applyFilters() {
      this.tempItems = this.items.filter(
        (item) => item.year === this.selectedYear && (this.selectedMonth === null || item.month === this.selectedMonth)
      );

      this.getFinishedTasks();
    },

    searchByPeriod() {
      this.fetchTasks();
    },

    searchByYear() {
      this.applyFilters();
    },

    searchByMonth() {
      this.applyFilters();
    },

    getFinishedTasks() {
      let finishedTasks = this.tempItems.filter((element) => {
        return element.estatus == 2;
      });

      this.finishedTasks = finishedTasks.length;
      console.log(finishedTasks.length);
    },

    generateExcel() {
      this.loading = true;

      const transformedData = this.tempItems
        .map((item) => {
          return {
            'Id De registro': item.idResponsabilities,
            ...item,
            estatus: item.estatus === 0 ? 'Pendiente' : item.estatus === 1 ? 'En progreso' : 'Completado',
            tipo: item.tipo === true ? 'Mensual' : 'Anual',
            Usuario: item.userName,
            año: item.year,
            mes: item.month + 1
          };
        })
        .map(({ idResponsabilities, userName, year, month, ...rest }) => rest);
      // Crear una hoja de trabajo
      const ws = XLSX.utils.json_to_sheet(transformedData);

      // Crear un libro de trabajo con la hoja
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Datos');

      // Generar el archivo Excel
      const excelFile = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

      // Crear un Blob y descargar el archivo
      const blob = new Blob([excelFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `Reporte de responsanbilidades ${this.selectedPeriod} de ${this.selectedMonth + 1} del ${this.selectedYear}.xlsx`;
      link.click();
      this.loading = false;
    }
  },
  async mounted() {
    // const response = await this.axios.get(services.routes.getAllMonthlyTasks);
    this.userCompanies = this.$session.get('companies').map((company) => company.nombre);
    console.log(this.userCompanies);

    this.userName = this.$session.get('name');
    // this.userName = 'Alexandro';
    this.rol = this.$session.get('rol');
    this.rol = this.rol[0];
    console.log(this.rol);

    try {
      this.initializeHeaders();
      // Espera a que se complete la carga de tareas
      await this.fetchTasks();

      // Aplica el filtro inicial por año
      this.applyFilters();
    } catch (error) {
      console.error('Error al obtener las tareas mensuales:', error);
    }
  }
};
</script>

<style scoped>
.v-data-table {
  margin-top: 1rem;
}

.status-chip {
  min-width: 95px;
  max-width: 95px;
  display: flex;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
